@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.banner-container-expo {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999999;

  .banner-componente {
    display: flex;
    justify-content: center;
    width: 1057px;
    height: 594px;
    border-radius: 50px;
    border: 5px solid #fff;
    background: #0065dd;

    .content-header {
      position: relative;
      width: 100%;
      padding: 38px 30px 12px 50px;
      display: flex;
      flex-direction: column;
      border-radius: 0 0px 56px 56px;

      .close-icon {
        display: flex;
        position: absolute;
        top: -35px;
        right: -30px;
        padding: 0px;
        cursor: pointer;
      }

      .chicasexpo {
        display: flex;
        position: absolute;
        bottom: 0;
        right: -49px;
        z-index: 4;
        width: 79%;
      }

      .title {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        height: auto;
        overflow: hidden;
        flex: 0 1 auto;
        z-index: 2;

        .iconTeamwork {
          display: flex;
          width: 100%;
          margin-bottom: 18px;
        }

        .iconExpotalentosas {
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          height: 80%;
          z-index: 9999;
        }

        .titulo1 {
          color: #fff;
          font-size: 31px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          width: 70%;
        }

        .titulo2 {
          color: #fff;
          font-size: 61px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-bottom: 24px;
          width: 70%;
        }

        .button-expo {
          display: flex;
          padding: 16px 40px;
          width: 283px;
          height: 56px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background: #fd0a90;
          color: #fff;
          font-family: Roboto;
          font-size: 21px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
        }
      }
    }
  }
}

/* 🔽 Estilos para móviles */
@media screen and (max-width: 768px) {
  .banner-container-expo {
    display: flex;

    .banner-componente {
      display: flex;
      justify-content: center;
      width: 95%;
      height: 514px;
      border-radius: 25px;
      border: 5px solid #fff;
      background: #0065dd;

      .content-header {
        position: relative;
        width: 100%;
        padding: 30px 25px 0px;
        display: flex;
        flex-direction: column;
        border-radius: 0 0px 56px 56px;

        .close-icon {
          display: flex;
          position: absolute;
          top: -35px;
          right: -30px;
          padding: 0px;
          cursor: pointer;
        }

        .chicasexpo {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 4;
          width: 100%;
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 100%;
          height: auto;
          z-index: 2;

          .iconTeamwork {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 18px;
          }

          .iconExpotalentosas {
            display: none;
          }

          .titulo1 {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 100%;
            margin-bottom: 12px;
          }

          .titulo2 {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 24px;
            width: 100%;
          }

          .button-expo {
            display: flex;
            padding: 16px 40px;
            width: 90%;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background: #fd0a90;
            color: #fff;
            font-family: Roboto;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
          }
        }
      }
    }
  }
}
